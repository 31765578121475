import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from './LanguageContext';

const StreamingLogs = ({ logUrl, videoUrl, onProcessingComplete }) => {
  const [logs, setLogs] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoProgress, setVideoProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const logContainerRef = useRef(null);

  const { language } = useLanguage();
  const translations = {
    en: {
      loading: 'Loading logs...',
      noLogs: 'No logs available yet. Please wait...',
      completed: 'Video generation completed successfully! You can download the video at:',
      progress: 'Complete',
      retryMessage: 'Waiting for logs to be available... (Attempt ',
      retryOf: ' of ',
      errorMessage: 'Video generation encountered an error. Please check the logs.\n\n',
      fetchError: 'Error fetching logs: '
    },
    zh: {
      loading: '正在加载日志...',
      noLogs: '暂无日志，请稍候...',
      completed: '视频生成已成功完成！您可以通过以下链接下载视频：',
      progress: '已完成',
      retryMessage: '等待日志可用... (第 ',
      retryOf: ' 次，共 ',
      errorMessage: '视频生成过程中发生错误。请检查日志。\n\n',
      fetchError: '获取日志时出错：'
    }
  };

  const t = translations[language];

  useEffect(() => {
    let isMounted = true;
    let retryCount = 0;
    const maxRetries = 20;
    const pollingInterval = 2000;

    const fetchLogs = async () => {
      if (isCompleted) return;

      try {
        const response = await fetch(logUrl);
        if (!response.ok) {
          if (retryCount < maxRetries) {
            retryCount++;
            setError(`${t.retryMessage}${retryCount}${t.retryOf}${maxRetries})`);
            return;
          } else {
            throw new Error('Unable to fetch logs after multiple attempts.');
          }
        }

        const text = await response.text();

        if (isMounted) {
          setLogs(text);
          setError(null);
          setIsLoading(false);
          retryCount = 0;

          const progressMatches = text.match(/Current video generation progress: (\d+(\.\d+)?)%/g);
          if (progressMatches) {
            const lastProgressMatch = progressMatches[progressMatches.length - 1];
            const progress = parseFloat(lastProgressMatch.match(/(\d+(\.\d+)?)%/)[1]);
            setVideoProgress(progress);
          }

          if (text.includes('Video generation completed') || text.includes('Processing completed')) {
            setVideoProgress(100);
            setIsCompleted(true);
            onProcessingComplete?.();
          } else if (text.toLowerCase().includes('error')) {
            setError(`${t.errorMessage}${text}`);
            setIsCompleted(true);
          }
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
        if (isMounted) {
          setError(`${t.fetchError}${error.message}`);
          setIsLoading(false);
          setIsCompleted(true);
        }
      }
    };

    const intervalId = setInterval(fetchLogs, pollingInterval);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [logUrl, isCompleted, onProcessingComplete, t.errorMessage, t.fetchError, t.retryMessage, t.retryOf]);

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <div className="space-y-6">
      <div className="relative pt-1">
        <div className="bg-gray-200 rounded-full h-4 overflow-hidden">
          <div
            className="bg-blue-600 h-4 rounded-full transition-all duration-500 ease-out relative"
            style={{ width: `${videoProgress}%` }}
          >
            {videoProgress > 10 && (
              <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-medium">
                {videoProgress.toFixed(1)}%
              </span>
            )}
          </div>
        </div>
        {videoProgress <= 10 && (
          <p className="text-center mt-2 text-sm text-gray-600">
            {videoProgress.toFixed(1)}% {t.progress}
          </p>
        )}
      </div>

      <div
        ref={logContainerRef}
        className="bg-gray-900 text-gray-100 p-6 rounded-lg h-64 overflow-y-auto font-mono text-sm"
      >
        {isLoading ? (
          <p className="text-yellow-400">{t.loading}</p>
        ) : error ? (
          <pre className="text-red-400 whitespace-pre-wrap">{error}</pre>
        ) : logs ? (
          <pre className="whitespace-pre-wrap">{logs}</pre>
        ) : (
          <p className="text-yellow-400">{t.noLogs}</p>
        )}
      </div>

      {isCompleted && !error && (
        <div className="bg-green-50 border border-green-200 rounded-lg p-4">
          <p className="text-green-700 font-medium mb-2">{t.completed}</p>
          <a 
            href={videoUrl} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:text-blue-800 hover:underline break-all"
          >
            {videoUrl}
          </a>
        </div>
      )}
    </div>
  );
};

export default StreamingLogs;
