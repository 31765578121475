import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from './LanguageContext';
import { Loader2 } from 'lucide-react';
import config from './config';

const NotesEditor = ({ notes, onSubmit, onCancel, uploadedFile }) => {
  const { language } = useLanguage();
  const [editedNotes, setEditedNotes] = useState(notes);
  const [slidePreviews, setSlidePreviews] = useState(new Array(notes.length).fill(null));
  const [loadingPreviews, setLoadingPreviews] = useState(false);
  const [previewError, setPreviewError] = useState(null);
 const isLoadingRef = useRef(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const generatePreviews = async () => {
      if (!uploadedFile || isLoadingRef.current) return;

      try {
        isLoadingRef.current = true;
        setLoadingPreviews(true);
        setPreviewError(null);

        const formData = new FormData();
        formData.append('ppt2video', uploadedFile);

        const response = await fetch(`${config.apiBaseUrl}/api/GeneratePreview`, {
          method: 'POST',
          body: formData,
          mode: 'cors',
          credentials: 'omit',
          signal: controller.signal
        });

        if (!response.ok) {
          throw new Error('Failed to generate previews');
        }

        const data = await response.json();
        
        if (isMounted && Array.isArray(data.previews)) {
          setSlidePreviews(data.previews);
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        
        console.error('Error generating previews:', error);
        if (isMounted) {
          setPreviewError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoadingPreviews(false);
         isLoadingRef.current = false;
        }
      }
    };

    generatePreviews();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [uploadedFile]);

  const handleNoteChange = (index, value) => {
    const newNotes = [...editedNotes];
    newNotes[index] = {
      ...newNotes[index],
      edited: value
    };
    setEditedNotes(newNotes);
  };

  const handleSubmit = () => {
    const processedNotes = editedNotes.map(note => ({
      ...note,
      edited: note.edited || note.suggested
    }));
    console.log('Submitting notes:', processedNotes);
    onSubmit(processedNotes);
  };

  const translations = {
    en: {
      title: 'Edit Speaker Notes',
      original: 'Original Note',
      suggested: 'AI Suggested',
      edited: 'Your Edit',
      submit: 'Generate Video',
      cancel: 'Cancel',
      slide: 'Slide',
      loadingPreviews: 'Loading previews...'
    },
    zh: {
      title: '编辑字幕',
      original: '原始字幕',
      suggested: 'AI建议',
      edited: '最终字幕',
      submit: '生成视频',
      cancel: '取消',
      slide: '幻灯片',
      loadingPreviews: '加载预览中...'
    }
  };

  const t = translations[language];

  const renderPreview = (index) => {
    if (previewError) {
      return (
        <div className="w-full h-full flex items-center justify-center text-red-500">
          <div className="text-center">
            <p>Error</p>
            <p className="text-sm">{previewError}</p>
          </div>
        </div>
      );
    }

    if (loadingPreviews || !slidePreviews[index]) {
      return (
        <div className="w-full h-full flex items-center justify-center text-blue-500">
          <Loader2 className="w-8 h-8 animate-spin" />
        </div>
      );
    }

    return (
      <img
        src={`data:image/png;base64,${slidePreviews[index]}`}
        alt={`${t.slide} ${index + 1}`}
        className="w-full h-full object-contain opacity-0 animate-fade-in"
      />
    );
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <h2 className="text-2xl font-semibold text-gray-800">{t.title}</h2>
          {loadingPreviews && (
            <div className="flex items-center gap-2 text-blue-500">
              <Loader2 className="w-4 h-4 animate-spin" />
              <span className="text-sm">{t.loadingPreviews}</span>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-8">
        {editedNotes.map((note, index) => (
          <div key={index} className="flex gap-6 p-6 border border-gray-200 rounded-xl hover:border-blue-200 transition-colors duration-200">
            {/* 幻灯片预览 */}
            <div className="w-56 flex-shrink-0">
              <div className="aspect-[4/3] bg-gray-50 rounded-lg overflow-hidden shadow-sm">
                {renderPreview(index)}
              </div>
            </div>

            {/* 笔记编辑区域 */}
            <div className="flex-grow space-y-4">
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1">{t.original}:</p>
                <p className="text-gray-700 bg-gray-50 rounded-lg p-3">{note.original || '无'}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1">{t.suggested}:</p>
                <p className="text-gray-700 bg-gray-50 rounded-lg p-3">{note.suggested}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1">{t.edited}:</p>
                <textarea
                  className="w-full p-3 border border-gray-200 rounded-lg focus:border-blue-300 focus:ring-2 focus:ring-blue-100 transition-all duration-200"
                  value={note.edited || note.suggested}
                  onChange={(e) => handleNoteChange(index, e.target.value)}
                  rows={4}
                  placeholder={t.editPlaceholder}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="sticky bottom-0 bg-white border-t border-gray-200 mt-8 pt-4 pb-2 flex justify-end gap-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200"
        >
          {t.cancel}
        </button>
        <button
          onClick={handleSubmit}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2"
        >
          {t.submit}
        </button>
      </div>
    </div>
  );
};

export default NotesEditor;
