import React, { useState, useRef } from 'react';
import { Upload } from 'lucide-react';
import NotesEditor from './NotesEditor';
import StreamingLogs from './StreamingLogs';
import { useLanguage } from './LanguageContext';
import config from './config';

const App = () => {
  const { language, setLanguage } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showNotesEditor, setShowNotesEditor] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [logUrl, setLogUrl] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isVideoProcessingComplete, setIsVideoProcessingComplete] = useState(false);
  const fileInputRef = useRef(null);
  const apiUrl = config.apiBaseUrl;

  const generateInitialNotes = async (file) => {
    const formData = new FormData();
    formData.append('ppt2video', file);

    try {
      setLoading(true);
      
      console.log('Sending request to:', `${apiUrl}/api/GenerateNotes`);
      
      const response = await fetch(`${apiUrl}/api/GenerateNotes`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received response:', data);
      
      if (!data.notes || !Array.isArray(data.notes)) {
        throw new Error('Invalid response format: notes array not found');
      }

      setNotes(data.notes);
      setShowNotesEditor(true);
      setUploadedFile(file);
    } catch (error) {
      console.error('Error generating notes:', error);
      alert(`${t.generateError}${error.message}`);
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      generateInitialNotes(file);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      generateInitialNotes(e.dataTransfer.files[0]);
    }
  };

  const handleNotesSubmit = async (editedNotes) => {
    try {
      setLoading(true);
      
      // 创建FormData对象并添加文件和notes
      const formData = new FormData();
      formData.append('ppt2video', uploadedFile);
      
      // 确保notes是正确的格式并转换为JSON字符串
      const notesData = editedNotes.map(note => ({
        edited: note.edited || note.suggested,
        suggested: note.suggested,
        original: note.original
      }));
      
      console.log('Notes being sent:', notesData); // 添加日志
      formData.append('notes', JSON.stringify(notesData));
      
      console.log('Sending request to:', `${apiUrl}/api/ProcessSlide`);
      
      // 检查formData内容
      for (let pair of formData.entries()) {
        console.log('FormData entry:', pair[0], pair[1]);
      }
      
      const response = await fetch(`${apiUrl}/api/ProcessSlide`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received response:', data);
      
      setVideoUrl(data.video_url);
      setLogUrl(data.log_url);
      setShowNotesEditor(false);
    } catch (error) {
      console.error('Error processing file:', error);
      alert(`${t.processError}${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const translations = {
    en: {
      title: 'PPTX to Video',
      subtitle: 'Convert your presentations into professional videos with AI narration',
      convertTitle: 'Convert Slide to Video',
      uploadButton: 'Upload PPT(X) File',
      dragDropText: 'Drag and drop your PPT(X) file here or click to choose',
      processing: 'Processing your request...',
      uploadProgress: 'Uploaded',
      videoProcessingStarted: 'Video processing started. Please check the logs below for progress:',
      downloadPrompt: 'Once processing is complete, you can download the video at:',
      newConversion: 'Convert Another Video',
      generateError: 'Error generating subtitles: ',
      processError: 'Error processing file: ',
    },
    zh: {
      title: 'PPT转视频',
      subtitle: '使用AI将PPT转换为专业视频',
      convertTitle: '将幻灯片转换为视频',
      uploadButton: '上传 PPT(X) 文件',
      dragDropText: '将 PPT(X)文件拖放到此处或点击选择',
      processing: '正在处理您的请求...',
      uploadProgress: '已上传',
      videoProcessingStarted: '视频处理已开始，请查看下方日志了解进度：',
      downloadPrompt: '处理完成后，您可以在以下链接下载视频：',
      newConversion: '转换新视频',
      generateError: '生成字幕时出错：',
      processError: '处理文件时出错：',
    }
  };

  const t = translations[language];

  const resetState = () => {
    setNotes([]);
    setShowNotesEditor(false);
    setVideoUrl('');
    setLogUrl('');
    setUploadedFile(null);
    setUploadProgress(0);
    setIsVideoProcessingComplete(false);
  };

  const renderFileUpload = () => (
    <div className="bg-white rounded-xl shadow-lg p-8">
      <div 
        className={`border-2 border-dashed ${
          dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
        } rounded-lg p-8 text-center transition-colors duration-200`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept=".pptx,.ppt"
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current.click()}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors duration-200 flex items-center justify-center mx-auto mb-4"
        >
          <Upload className="w-5 h-5 mr-2" />
          {t.uploadButton}
        </button>
        <p className="text-gray-600">{t.dragDropText}</p>
      </div>
      {uploadProgress > 0 && (
        <div className="mt-6">
          <div className="bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-600 h-2 rounded-full transition-all duration-300" 
              style={{width: `${uploadProgress}%`}}
            />
          </div>
          <p className="text-center mt-2 text-gray-600">
            {uploadProgress}% {t.uploadProgress}
          </p>
        </div>
      )}
    </div>
  );

  const renderLoadingOverlay = () => (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-xl">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-gray-800 text-center font-medium">{t.processing}</p>
      </div>
    </div>
  );

  const renderLanguageToggle = () => (
    <button
      onClick={() => setLanguage(language === 'en' ? 'zh' : 'en')}
      className="fixed top-4 right-4 bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg shadow-lg hover:bg-white transition-colors duration-200"
    >
      {language === 'en' ? '中文' : 'English'}
    </button>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 p-4 md:p-8">
      {renderLanguageToggle()}
      <div className="max-w-5xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
            {t.title}
          </h1>
          <p className="text-gray-600 text-lg">{t.subtitle}</p>
        </div>

        <div className="space-y-8">
          {!showNotesEditor && !videoUrl && renderFileUpload()}

          {showNotesEditor && (
            <NotesEditor 
              notes={notes} 
              onSubmit={handleNotesSubmit}
              onCancel={() => setShowNotesEditor(false)}
              uploadedFile={uploadedFile}
            />
          )}

          {videoUrl && (
            <div className="space-y-8">
              <div className="bg-white rounded-xl shadow-lg p-8">
                <StreamingLogs 
                  logUrl={logUrl} 
                  videoUrl={videoUrl} 
                  onProcessingComplete={() => setIsVideoProcessingComplete(true)}
                />
              </div>
              
              {isVideoProcessingComplete && (
                <div className="flex justify-center">
                  <button
                    onClick={resetState}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors duration-200 flex items-center"
                  >
                    <Upload className="w-5 h-5 mr-2" />
                    {t.newConversion}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {loading && renderLoadingOverlay()}
      </div>
    </div>
  );
};

export default App;
